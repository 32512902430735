import { render, staticRenderFns } from "./cleaning-detail.vue?vue&type=template&id=532a2ba4&"
import script from "./cleaning-detail.vue?vue&type=script&lang=ts&"
export * from "./cleaning-detail.vue?vue&type=script&lang=ts&"
import style0 from "./cleaning-detail.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\git.fe\\jtl3d-bi-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('532a2ba4')) {
      api.createRecord('532a2ba4', component.options)
    } else {
      api.reload('532a2ba4', component.options)
    }
    module.hot.accept("./cleaning-detail.vue?vue&type=template&id=532a2ba4&", function () {
      api.rerender('532a2ba4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/dashboard/cleaning/cleaning-detail.vue"
export default component.exports