



















import { Component, Ref, Prop, Mixins } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import DrawerComponent from '@/mixins/drawer-component';
import FormComponent from '@/mixins/form-component';
import { CleaningEntityModel } from '@/entity-model/cleaning-entity';
import { ViewModeType } from '@/model/enum';
import CleaningService from '@/service/cleaning';
import JTLTransferComponent from '@/components/jtl-transfer/index.vue';
import { DATE_FORMAT } from '@/filter/date-format';
import moment, { Moment } from 'moment';

@Component({
    components: {
        'jtl-transfer-component': JTLTransferComponent
    }
})
export default class CleaningDetailComponent extends Mixins(BaseComponent, DrawerComponent) {
    @Prop()
    record;
    @Prop()
    viewType;
    visible:boolean =false;
    title:string='';
    transferData: any = [];
    keys: string[] = [];
    currentFormControl:any = undefined;
    ViewModeType = ViewModeType;
    cleaningEntityModel:CleaningEntityModel;
    service:any;

    /**
     * 表单组件
     */
    @Ref()
    basicForm: FormComponent<any>;

    created() {
        this.service = CleaningService;
        this.$nextTick(() => {
            if (this.record) {
                this.cleaningEntityModel = this.record;
                this.cleaningEntityModel['dateRange'] = [moment(this.cleaningEntityModel.beginDate, 'YYYY-MM-DD'), moment(this.cleaningEntityModel.endDate, 'YYYY-MM-DD')];
            } else {
                this.cleaningEntityModel = new CleaningEntityModel();
                this.basicForm.initForm(this.cleaningEntityModel, ViewModeType.NEW);
            }
        });
    }

    save() {
        this.basicForm.submitForm().then(res => {
            this.basicForm.formModel.beginDate = (_.get(this.basicForm.formModel.dateRange, '[0]') as Moment)?.startOf('day').format(DATE_FORMAT);
            this.basicForm.formModel.endDate = (_.get(this.basicForm.formModel.dateRange, '[1]') as Moment)?.startOf('day').add(1, 'day').format(DATE_FORMAT);
            delete this.basicForm.formModel.dateRange;
            if (this.basicForm.formModel.uuid) {
                delete this.basicForm.formModel.uuid;
            }
            if (this.basicForm.formModel.createdTime) {
                delete this.basicForm.formModel.createdTime;
            }

            this.service.create(this.basicForm.formModel).then(res => {
                this.cancel();
            });
        });
    }

    formTableAdd(item) {
        if (item) {
            this.currentFormControl = item;
            this.title = item.label;
            if (item.dataService) {
                item.dataService().then(res => {
                    this.transferData = res.map(dt => {
                        return { key: dt.value, title: dt.name, type: dt.type };
                    });
                    this.keys = this.basicForm.formModel[this.currentFormControl.key].map(dt => { return dt.key; });
                    this.visible = true;
                });
            }
        }
    }

    handleOk() {
        this.basicForm.formModel[this.currentFormControl.key] = this.$refs['transfer']['targetData'];
        this.visible = false;
    }

    handleCancel() {
        this.visible = false;
    }

    cancel() {
        this.$emit('showDrawer');
    }
}
