
import { CleaningEntityModel, CleaningQueryModel } from '@/entity-model/cleaning-entity';
import { ICRUDQ } from '@/model/interface';
import { get, post, put, del } from './request';

const URL_PATH = `${BI_BASE_REQUEST_PATH}/cleaning`;

class CleaningService implements ICRUDQ<CleaningEntityModel, CleaningQueryModel> {
    async create(model: CleaningEntityModel):Promise<CleaningEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<CleaningEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new CleaningEntityModel().toModel(res);
    }

    async update(model: CleaningEntityModel):Promise<CleaningEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: CleaningEntityModel):Promise<CleaningEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: CleaningQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new CleaningEntityModel().toModel(item));
        return res;
        // 不带分页功能的处理方法
        // return _.map(res, item => item = new InspectionEntityModel().toModel(item));
    }
}

export default new CleaningService();
